<template>
  <div class="home">
    <div class="home-hero">
      <div class="home-hero-logo">
        <img src="@/assets/img/logo.png" alt="" srcset="" />
        Меняем жизнь к лучшему!
      </div>
    </div>

    <div class="home-body">
      <div class="home-body-title">
        цифровые ресурсы в поддержку вашей практики
      </div>

      <a href="https://www.hillsvet.ru" target="_blank" class="home-body-item">
        <img src="@/assets/img/Laptop.png" alt="" srcset="" />
        <div class="home-body-item-text">
          <div class="home-body-item-text__title">Hillsvet.ru</div>
          <div class="home-body-item-text__text">
            Информация об образовательных программах, продукции и другие
            полезные ресурсы
          </div>
        </div>
      </a>

      <a href="https://ru.hills4me.com" target="_blank" class="home-body-item">
        <img src="@/assets/img/Dog.png" alt="" srcset="" />
        <div class="home-body-item-text">
          <div class="home-body-item-text__title">Quick Reco</div>
          <div class="home-body-item-text__text">
            Индивидуальные планы питания для ваших пациентов
          </div>
        </div>
      </a>

      <div class="home-body-item" @click="isTelegram = true">
        <img src="@/assets/img/Telegram.png" alt="" srcset="" />
        <div class="home-body-item-text">
          <div class="home-body-item-text__title">Чат-бот</div>
          <div class="home-body-item-text__text">
            Связь с нами 24/7, обучение, актуальные мероприятия и свежие новости
          </div>
        </div>
      </div>

      <!-- <a
        href="https://planetahills.ru/ru/news"
        target="_blank"
        class="home-body-item"
      >
        <img src="@/assets/img/Hills.png" alt="" srcset="" />
        <div class="home-body-item-text">
          <div class="home-body-item-text__title">Planetahills.ru</div>
          <div class="home-body-item-text__text">
            Покупка Hill’s по привлекательной цене
          </div>
        </div>
      </a> -->

      <!-- <a
        target="_blank"
        href="https://www.hillsvet.ru/focus-toolkit-main"
        class="home-body-item"
      >
        <img src="@/assets/img/Library.png" alt="" srcset="" />
        <div class="home-body-item-text">
          <div class="home-body-item-text__title">Библиотека категорий</div>
          <div class="home-body-item-text__text">
            Обучающие материалы по основным категориям заболеваний
          </div>
        </div>
      </a> -->

      <div class="home-body__text">
        Мы хотим упростить вашу работу, чтобы у вас было больше времени на то,
        что получается лучше всего — заботу о животных
      </div>
    </div>

    <div class="qr-modal" v-if="isTelegram">
      <div class="overlay" @click="isTelegram = false"></div>
      <div class="qr-modal-content">
        <img src="@/assets/img/Telegram.png" alt="" srcset="" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Home',
  data() {
    return {
      isTelegram: false,
    };
  },
  components: {},
  methods: {
    goToLink(link = null) {
      window.location.href = link;
    },
  },
};
</script>

<style lang="scss" scoped>
.home {
  font-family: Montserrat;
  max-width: 800px;
  margin: 0 auto;
  &-hero {
    width: 100%;
    height: 135px;
    background-image: url('/dog.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    position: relative;
    &-logo {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      position: absolute;
      top: 10px;
      left: 18px;
      font-size: 5.581px;
      font-style: normal;
      font-weight: 600;
      line-height: 132.9%;
      letter-spacing: -0.028px;
      color: #fff;
      img {
        width: 44px;
      }
    }
  }
  &-body {
    padding: 10px 18px;
    &-title {
      color: #44464b;
      text-align: center;
      font-family: Montserrat;
      font-size: 17px;
      font-style: normal;
      font-weight: 700;
      line-height: 128%;
      text-transform: uppercase;
      margin-bottom: 25px;
    }
    &__text {
      color: #0054a4;
      text-align: center;
      font-family: Montserrat;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 132.9%;
    }

    &-item {
      display: flex;
      // justify-content: center;
      align-items: flex-start;
      gap: 20px;
      flex-shrink: 0;
      text-decoration: none;
      cursor: pointer;
      &:not(:last-child) {
        margin-bottom: 20px;
      }
      img {
        width: 52px;
      }
      &-text {
        &__title {
          color: #44464b;
          font-size: 16px;
          font-style: normal;
          font-weight: 700;
          line-height: 132.9%;
          margin-bottom: 5px;
          color: #0054a4;
          border-bottom: 1px solid #0054a4;
          display: inline-block;
        }
        &__text {
          color: #44464b;
          font-size: 10px;
          font-style: normal;
          font-weight: 500;
          line-height: 128%;
        }
      }
    }
  }
}

.qr-modal {
  display: flex;
  align-items: center;
  justify-content: center;
  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba($color: #000, $alpha: 0.25);
  }

  &-content {
    position: absolute;
    top: 50%;
    img {
      width: 128px;
    }
  }
}
</style>
